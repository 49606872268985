<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 당근농장 등록"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <NewsLetterTab :idx="0" :tidx="1"></NewsLetterTab>
                <div>
                  <ul>
                    <li>- 캐럿HUB사이트의 사보 당근농장 다시 읽기 영역을 관리할 수 있습니다.</li>
                    <li>- 첨부파일은 각각 최대 3MB까지 업로드 가능합니다.</li>
                    <li>* CARROT HUB : <a href="https://carrotglobal.com/news-letter">https://carrotglobal.com/news-letter</a></li>
                  </ul>
                </div>
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 발행년도</th>
                                <td>
                                    <select v-model="bbs.year" class="w-100px" >
                                        <option v-for="year in bbs.years" :key="year" :value="year">{{year}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 발행월</th>
                                <td>
                                    <select v-model="bbs.month" class="w-100px" >
                                        <option :value="m" v-for="m in bbs.months" :key="m" >{{m}}월</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span>제목</th>
                              <td>
                                <input type="text" v-model="bbs.title" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 미리보기</th>
                              <td>
                                <input type="text" v-model="bbs.preview" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>썸네일</th>
                                <td>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr v-for="(irow, i) in bbs.indexs" :key="i">
                                <th><span class="txt-red">*</span>인덱스 {{i+1}}</th>
                                <td>
                                    <input type="text" class="w-100per mb-10" v-model="bbs.indexs[i].index_title">
                                    <CarrotFileUploader v-for="j in bbs.index_file_length" :key="j" :ref="`iFile${i+1}`" ></CarrotFileUploader>
                                   
                                    <button v-if="i==0" @click="bbs.addIndexs()" class="btn-default float-right">추가</button>
                                    <button v-else @click="bbs.delIndexs(i)" class="btn-default w-65px float-right">삭제</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">게시하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import NewsLetterTab from '@/components/common/NewsLetterTab.vue'

export default {
    layout:"ResourceManagement",
    components: {
      //CarrotDatePicker,        
      // CarrotEditor
        NewsLetterTab
    },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const file1 = ref(null);
    
        const iFile1 = ref([]);
        const iFile2 = ref([]);
        const iFile3 = ref([]);
        const iFile4 = ref([]);
        const iFile5 = ref([]);
        const iFile6 = ref([]);
        const iFile7 = ref([]);
        const iFile8 = ref([]);
        const iFile9 = ref([]);
        const iFile10 = ref([]);
        const iFile11 = ref([]);
        const iFile12 = ref([]);
        const iFile13 = ref([]);
        const iFile14 = ref([]);
        const iFile15 = ref([]);
        
            
        const bbs = reactive({
            topfix: false,
            years : [],
            months : [],
            year : "",
            month : "",
            title : "",
            preview : "",
            index_length : 15,
            index_file_length : 8,
            indexs : [
               
            ],
            onFileChange: (e, i) => {
                bbs.indexs[i].index_file[0] = e.target.files[0];
            },
          
            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },

            addIndexs : () => {
                if(bbs.indexs.length < bbs.index_length) {
                    bbs.indexs.push({ index_title : '', index_file: []});
                } else {
                    toast.error(`최대 추가 갯수는 ${bbs.index_length}개 입니다. 추가를 원하시면 문의 주세요`);
                }
            },

            delIndexs : (i) => {
                bbs.indexs.splice(i, 1);
            },
            onSave: () => {
                
                if( !bbs.year ){
                    toast.error("발행년도를 입력하세요.");
                    return;
                }
                if( !bbs.month ){
                    toast.error("발행월을 입력하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.preview ){
                  toast.error("미리보기를 입력하세요.");
                  return;
                }

                if(file1.value.file.filedata===null) {
                    toast.error("썸네일을 등록하세요.");
                    return;
                }

                for(var i in bbs.indexs) {
                    if(!bbs.indexs[i].index_title) {
                        toast.error("인덱스 제목을 입력하세요.");
                        return;
                    }
                }

                var formData = new FormData();
                formData.append("year", bbs.year);
                formData.append("month", bbs.month);
                formData.append("title", bbs.title);
                formData.append("preview", bbs.preview);

                if(file1.value.file.filedata!==null) {
                    formData.append("nfile[]", file1.value.file.filedata);
                }
                
                for(i in bbs.indexs) {
                    formData.append("index_title[]", bbs.indexs[i].index_title);
                }

                 //인덱스 제목 파일
                for(i=1; i <= bbs.index_length; i++) {
                    
                    var iFile = eval(new String(`iFile${i}`).toString());
                    for(var j=0; j <= bbs.index_file_length; j++) {

                        if(iFile.value[j]?.file.filedata) {
                          //formData.append(`index_files${i}_${j+1}`, iFile.value[j].file.filedata);
                            formData.append(`index_files${i}[]`, iFile.value[j].file.filedata); //index_file1[] ... index_file5[]
                        }
                    }
                }



                if(!confirm("등록하시겠습니까?")) return;

                axios.post('/rest/resourceManagement/newsletterAdd', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"ResourceManagement-NewsLetterManagement_tab1-idx",
                            params : { idx:res.data.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        

        onMounted(() => {
            // Mounted
            bbs.years = [];
            let ndate = new Date();
            let syear = ndate.getFullYear()-16;
            let eyear = ndate.getFullYear()+10;

            for(let i=syear;i<=eyear;i++) {
                bbs.years.push(i);
            }
            for(let i=1;i<=12;i++) {
                bbs.months.push(i);
            }

            bbs.month = ndate.getMonth() + 1;
            bbs.year = ndate.getFullYear();

            bbs.addIndexs();

            

        });

        return {bbs, file1,iFile1,iFile2,iFile3,iFile4,iFile5,iFile6,iFile7,iFile8,iFile9,iFile10,iFile11,iFile12,iFile13,iFile14,iFile15 };
    }
}
</script>

<style lang="scss" scoped>
</style>